export default [
  {
    label: 'Name',
    field: 'name',
  },
  {
    label: 'Purchases All time',
    field: 'cntPurchases',
  },
  {
    label: 'Sum Stars All time',
    field: 'sumStars',
  },
  {
    label: 'Sum USD All time',
    field: 'sumUsd',
  },
  {
    label: 'Daily Avg Stars All time',
    field: 'dailyAvgStats',
  },
  {
    label: 'Daily Avg Usd All time',
    field: 'dailyAvgUsd',
  },
  {
    // empty column for visual pause
    label: '',
    field: 'space',
  },
  {
    label: 'Purchases by period',
    field: 'periodPurchases',
  },
  {
    label: 'Sum Stars by period',
    field: 'periodSumStars',
  },
  {
    label: 'Sum USD by period',
    field: 'periodSumUsd',
  },
  {
    label: 'Daily Avg Stars by period',
    field: 'periodDailyAvgStats',
  },
  {
    label: 'Daily Avg Usd by period',
    field: 'periodDailyAvgUsd',
  },
]
