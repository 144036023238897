<template>
  <div>
    <AppTable
      class="select-last-row"
      :rows="items.value"
      :columns="columns"
      :is-pagination="false"
      :is-loading="requestInProgress"
      @change-limit="changePageLimit"
      @change-page="changePage"
    >
      <template #prependFilters>
        <b-row>
          <b-col
            class="mb-1"
            md="4"
            xl="4"
          >
            <b-form-group>
              <label for="datepicker-placeholder">{{ $t('form.label.startDate') }}</label>
              <b-form-datepicker
                v-model="dateFrom"
                :min="availableMinPeriodDayFrom"
                :max="availableMaxPeriodDayFrom"
                close-button
                local="en"
                :placeholder="$t('form.placeholder.minDate')"
                reset-button
                today-button
                @input="search"
              />
            </b-form-group>
          </b-col>

          <b-col
            class="mb-1"
            md="4"
            xl="4"
          >
            <b-form-group>
              <label for="datepicker-placeholder">{{ $t('form.label.endDate') }}</label>
              <b-form-datepicker
                v-model="dateTo"
                :min="availableMinPeriodDayTo"
                :max="availableMaxPeriodDayTo"
                close-button
                local="en"
                :placeholder="$t('form.placeholder.maxDate')"
                reset-button
                today-button
                @input="search"
              />
            </b-form-group>
          </b-col>

          <b-col
            class="mb-1 mt-auto pb-1"
            md="1"
            xl="1"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              :disabled="requestInProgress"
              class="mt-auto"
              type="button"
              variant="primary"
              @click="search"
            >
              <feather-icon
                icon="SearchIcon"
                size="18"
              />
            </b-button>
          </b-col>

          <b-col
            class="ml-auto mb-1 mt-2 mt-100 pb-1"
            md="3"
            xl="3"
          >
            <div class="d-flex justify-content-end text-right">
              By period: <br>from {{ startPeriod }} to {{ endPeriod }}
            </div>
          </b-col>
        </b-row>
      </template>
    </AppTable>
  </div>
</template>

<script>
import {
  BButton, BRow, BCol, BFormGroup, BFormDatepicker,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapActions } from 'vuex'

import waitRequest from '@/mixins/waitRequest'
import validationError from '@/mixins/validationError'
import formatDate from '@/mixins/formatDate'
import transactions from '@/mixins/transactions'
import columns from './config/tableConfig'
import AppTable from '@/components/AppTable.vue'

export default {
  name: 'TGStarsView',
  components: {
    BFormDatepicker,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    AppTable,
  },
  directives: {
    Ripple,
  },
  mixins: [waitRequest, validationError, formatDate, transactions],

  data() {
    return {
      DEFAULT_ORDER: 1000000,
      columns,
      currenPage: 1,
      selectPerPage: 30,
      rowsPerPageOptions: [10, 30, 50],

      dateTo: '',
      dateFrom: '',

      baseAggregations: null,
      aggregations: null,
      period: {
        min: null,
        max: null,
      },
      initTotal: {
        sumStars: null,
        sumUsd: null,
        dailyAvgStats: null,
        dailyAvgUsd: null,
      },

      aggregationSortOrder: {
        'farming.autocollect': 1,
        'clicker.games': 2,
        'clicker.retry': 3,
        'diamond-breath.game': 4,
        'gem-forge.craft': 5,
        'clicker.games-max-rarity': 6,
        'space-tapper.additional-games': 7,
        'space-tapper.additional-lives': 8,
        'space-tapper.skin.fintopio': 9,
        'space-tapper.skin.quantum-race': 10,
        'space-tapper.skin.friends-factory': 11,
        'space-tapper.skin.telegram': 12,
        // other DEFAULT_ORDER
      },

      aggregationTemplate: {
        cntPurchases: 0,
        sumStars: 0,
        sumUsd: 0,
        dailyAvgStats: 0,
        dailyAvgUsd: 0,
      },
    }
  },

  computed: {
    availableMinPeriodDayFrom() {
      return this.period.min
    },

    availableMaxPeriodDayFrom() {
      return this.dateTo || this.period.max
    },

    availableMinPeriodDayTo() {
      return this.dateFrom || this.period.min
    },

    availableMaxPeriodDayTo() {
      return this.period.max
    },

    startPeriod() {
      return this.dateFrom || this.period.min
    },

    endPeriod() {
      return this.dateTo || this.period.max
    },

    aggregationsToList() {
      const space = '' // visual pause between data
      const mergedObject = []
      const hasDateLimit = this.dateTo || this.dateFrom
      // eslint-disable-next-line guard-for-in,no-restricted-syntax
      for (const [key, initAggregation] of Object.entries({ ...this.baseAggregations })) {
        const avgAggregation = this.aggregations?.[key]

        const order = this.aggregationSortOrder[key] || this.DEFAULT_ORDER
        const transformedAvgObject = this.transformAggregations(
          hasDateLimit
            ? avgAggregation
            : this.baseAggregations[key],
        )

        mergedObject.push({
          ...this.formatAggregation(initAggregation),
          ...this.formatAggregation(transformedAvgObject),
          space,
          order,
        })
      }

      const total = {
        name: 'Total',
        space,
        ...this.formatAggregation(this.initTotal),
        ...this.formatAggregation(this.transformAggregations(hasDateLimit ? this.avgTotal : this.initTotal)),
        order: this.DEFAULT_ORDER,
      }

      return mergedObject.sort((a, b) => a?.order - b?.order).concat(total)
    },

    items() {
      return {
        value: this.aggregationsToList || [],
      }
    },
  },

  mounted() {
    this.initState()
  },

  methods: {
    ...mapActions({
      fetchBoostPurchasesAggregations: 'hold/fetchBoostPurchasesAggregations',
    }),

    createQueryConfig(config) {
      return {
        page: this.items?.page,
        limit: this.items?.limit,

        from: this.dateFrom?.replace(/-/g, '.') || null,
        to: this.dateTo?.replace(/-/g, '.') || null,

        ...config,
      }
    },

    initState() {
      const limit = this.selectPerPage
      const query = this.createQueryConfig({ limit })
      this.fetchBoostPurchasesAggregations(query).then(response => {
        this.baseAggregations = response?.data?.aggregations
        this.fillWithMock()
        this.initTotal = response?.data?.total

        this.period = response?.data?.period
        let minDate = new Date(response?.data?.period?.from)
        minDate = minDate?.toISOString()?.split('T')?.[0]
        let maxDate = new Date(response?.data?.period?.to)
        maxDate = maxDate?.toISOString()?.split('T')?.[0]

        this.period = {
          min: minDate,
          max: maxDate,
        }
      })
    },

    fillWithMock() {
      Object.keys(this.aggregationSortOrder).forEach(key => {
        if (!this.baseAggregations[key]) {
          this.baseAggregations[key] = { ...this.aggregationTemplate, name: key }
        }
      })
    },

    search() {
      const query = this.createQueryConfig({
        page: 1,
      })

      return this.fetchStars(query)
    },

    changePageLimit(pageLimit) {
      const query = this.createQueryConfig({ limit: pageLimit })
      return this.fetchStars(query)
    },
    changePage(pageNumber) {
      const query = this.createQueryConfig({ page: pageNumber })
      return this.fetchStars(query)
    },

    fetchStars(query) {
      return this.waitRequest(() => this.fetchBoostPurchasesAggregations(query).then(
        response => {
          this.aggregations = response?.data?.aggregations
          this.avgTotal = response?.data?.total
        },
      ).catch(this.checkErrors))
    },

    formatNumber(num) {
      const numParts = `${num}`.split('.')
      const formattedWholePart = numParts[0]
        .split('')
        .reverse()
        .reduce((acc, digit, i, ar) => {
          acc.push(digit)
          if (((i + 1) % 3 === 0) && (i !== ar.length - 1)) acc.push(',')
          return acc
        }, [])
        .reverse()
        .join('')
      const formattedNum = numParts[1] && formattedWholePart <= 100
        ? formattedWholePart.concat('.', numParts[1])
        : formattedWholePart
      // on 07.03.25 fractional part is formatted form back-end

      return formattedNum
    },

    formatAggregation(aggregation) {
      return Object.entries(aggregation)
        .reduce((acc, [key, value]) => {
          acc[key] = Number.isFinite(value) ? this.formatNumber(value) : value
          return acc
        }, {})
    },

    transformAggregations(aggregations) {
      if (!aggregations) {
        return {
          periodPurchases: '0',
          periodSumStars: '0',
          periodSumUsd: '0',
          periodDailyAvgStats: '0',
          periodDailyAvgUsd: '0',
        }
      }
      return {
        periodPurchases: aggregations.cntPurchases,
        periodSumStars: aggregations.sumStars,
        periodSumUsd: aggregations.sumUsd,
        periodDailyAvgStats: aggregations.dailyAvgStats,
        periodDailyAvgUsd: aggregations.dailyAvgUsd,
      }
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-good-table.scss';

.coin-img {
  width: 30px;
  border-radius: 50%;
}

.select-last-row {
  .vgt-table {
    tr:nth-last-child(-n + 1) {
      td {
        background-color: rgba(51, 68, 109, 0.03) !important;
      }
    }

    tr:nth-of-type(odd) {
      background-color: white !important;
    }

    td:not(:first-child) {
      text-align: right;
      padding: 12px 24px 12px 12px;
    }

    th:not(:first-child) {
      text-align: right;
    }
  }
}

.dark-layout {
  .select-last-row {
    .vgt-table {
      tr:nth-last-child(-n + 1) {
        td {
          $theme-dark-card-bg-darker: #22293c !default;
          background-color: $theme-dark-card-bg-darker !important;
        }
      }
    }
  }
}
</style>
